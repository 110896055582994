/* Contact.css */

.contact-container {
  position: relative;
  width: 800px;
  margin: 20px auto;
  padding: 20px;
}

.contact-banner-image-container {
  position: relative; /* 텍스트를 이미지 위에 겹치기 위해 부모 컨테이너에 relative 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px; /* 높이를 200px로 설정하여 이미지 크기 고정 */
  object-fit: cover; /* 이미지 비율을 유지하면서 컨테이너를 채움 */
  z-index: 999; /* 다른 요소들 위에 있도록 설정 */
}
.banner-text-contact {
  position: absolute; /* 텍스트를 이미지 위에 겹치도록 설정 */
  color: #14172e; /* 텍스트 색상을 흰색으로 설정 */
  font-size: 2.0rem;
  font-weight: bold;
  top: 100px; /* 세로 위치 */
  left: 50%; /* 가로 중간 정렬 */
  transform: translate(-50%, -50%); /* 정확한 가운데 정렬을 위해 변환 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0); /* 텍스트 그림자로 가독성 향상 */
}

.google-map-container{
  padding-top: 80px;
  width: 55%;
  margin: 0 auto; /* 좌우 중앙 정렬 */
}

.contact-container article {
  padding: 10px 0;
  border-bottom: 2px solid #ccc;
}

.contact-container article:last-child {
  border-bottom: none;
}

.contact-container dt {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;

}

.container dd {
  margin: 0;
}

.email-form {
  width: 800px;
  margin: 70px auto;
  padding: 20px;
  border: 2px solid #ccc;
}

.email-form h3 {
  font-weight: bold;
  margin-bottom: 20px;
}

.email-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.email-form input,
.email-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
}

.email-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-form button:hover {
  background-color: #0056b3;
}


/* 모바일 대응 스타일 */
@media (max-width: 768px) {
  .contact-container {
    width: 90%; /* 모바일에서 화면 너비의 90%로 축소 */
    padding: 15px; /* 패딩을 줄여서 모바일 화면에 맞춤 */
  }

  .contact-container dt {
    font-size: 1.0rem;
  }

  .contact-container dd {
    font-size: 0.9rem;
  }

  .email-form {
    width: 90%; /* 화면 너비의 90%로 축소 */
    margin: 40px auto; /* 상하 마진을 줄여 모바일에 맞게 */
    padding: 15px; /* 패딩을 줄여서 모바일 화면에 맞춤 */
  }

  .google-map-container {
    width: 90%; /* 지도의 너비를 화면 너비의 90%로 축소 */
    padding-top: 40px; /* 위쪽 패딩을 줄임 */
  }

  .email-form h3 {
    font-size: 1.2rem;
  }

  .email-form label {
    font-size: 1.0rem;
  }

  .email-form input,
  .email-form textarea {
    padding: 8px; /* 입력 필드의 패딩을 줄여 모바일에서 보기 좋게 */
    font-size: 0.9rem;
  }

  .banner-text-contact {
    font-size: 1.5rem; /* 배너 텍스트 크기를 모바일에 맞게 줄임 */
  }
}

