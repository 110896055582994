/* 공통 컨테이너 스타일 */
.history-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* 컨테이너 내부 좌측 정렬 */
    margin-top: 30px;
    width: 100%; /* 전체 너비 사용 */
}

.history-container h2, .history-container h3 {
    font-weight: bold;
    margin-bottom: 20px;
}

.history-container > button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
}

/* 테이블 스타일 */
.history-table {
    width: 90%;
    max-width: 90%;
    table-layout: fixed; /* 테이블 너비 고정 */
    border-collapse: collapse;
    margin: 20px 20px;
}

.history-table th, .history-table td {
    border: 1px solid #ddd;
    padding: 5px 5px;
    text-align: center;
    word-wrap: break-word; /* 내용이 길 경우 줄바꿈 허용 */
}

.history-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.history-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.history-table tbody tr:hover {
    background-color: #f1f1f1;
}

.history-table td button {
    margin-right: 10px;
}

.history-table th:nth-child(1),
.history-table td:nth-child(1) {
    width: 5%; /* id 열 너비를 5%로 설정 */
}

.history-table th:nth-child(2),
.history-table td:nth-child(2) {
    width: 10%; /* date 열 너비를 10%로 설정 */
}

/* 특정 열의 너비 고정 */
.history-table th:nth-child(3),
.history-table td:nth-child(3) {
    width: 60%; /* Content 열 너비를 60%로 설정 */
}

.history-table td:nth-child(3) {
    text-align: left; /* Content 열만 좌측 정렬 */
}

.history-table th:nth-child(4),
.history-table td:nth-child(4) {
    width: 20%; /* actions 열 너비를 15%로 설정 */
}

/* 폼 스타일 */
.history-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px; /* 폼 너비 설정 */
    margin-top: 20px;
}

.history-container input, .history-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.history-container textarea {
    height: 100px;
    resize: none;
}

.form-button-container {
    display: flex;
    justify-content: center; /* 버튼 중앙 정렬 */
    gap: 10px;
    margin-bottom: 20px;
}

.section-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.section-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.section-buttons .active {
    background-color: #007bff; /* 활성화된 버튼의 배경색 */
    color: white;
}