/* Menu.css */
/* 메뉴 컨테이너 스타일 */
.menu-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #4e61aa;
  background: linear-gradient(360deg, rgb(141, 75, 239) 0%, #4e61aa 100%);
  padding-top: 100px;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.08, 0.65, 0.53, 0.96);
  z-index: 9999;

  /* 왼쪽 상단 모서리만 둥글게 */
  border-radius: 20px 0 0 0;  /* 왼쪽 상단만 20px 둥글게 */
}

.menu-open .menu-container {
  transform: translateX(0);
}

.menu-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-container li {
  text-align: left;
  position: relative;
  color: #ffffff;
  display: block;
  font-weight: bold;
  font-size: 24px;
  padding: 8px;
  opacity: 0;
  will-change: transform, opacity, filter;
}

.menu-open .menu-container li {
  opacity: 1;
  transform: scale(1);
}

.menu-container li:hover {
  color: #0b0909;
}

/* 드롭다운 메뉴 */
.dropdown-menu {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px 0;
  position: relative; /* 부모 li를 기준으로 절대 위치 설정 */
  top: 100%; /* 부모 버튼 바로 아래에 위치 */
  left: 0;
  z-index: 10000;
  width: 100%;
  pointer-events: auto; /* 드롭다운 항목 클릭 가능하도록 설정 */
}

.dropdown-item {
  text-align: center;
  padding: 3px !important;
  margin-bottom: 0; /* 텍스트 아래의 빈 공간 제거 */
  font-size: 15px !important;
}

.dropdown-link {
  text-decoration: none;
  color: #000000;
  font-size: 10px;
  padding: 0;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item.first-item:hover {
  border-radius: 10px 10px 0 0; /* 위쪽 모서리만 둥글게 */
}

.dropdown-item.last-item:hover {
  border-radius: 0 0 10px 10px; /* 아래쪽 모서리만 둥글게 */
}

.dropdown-item{
  text-decoration: none;
}

/* 모바일 화면에서 메뉴 크기와 텍스트 크기 조정 */
@media (max-width: 768px) {
  .menu-container {
    width: 55%; /* 모바일에서 메뉴 너비 줄임 */
    padding-top: 80px; /* 상단 여백 줄임 */
  }

  .menu-container li {
    font-size: 18px; /* 글자 크기를 작게 조정 */
    padding: 5px; /* 각 항목의 패딩 감소 */
  }

  .dropdown-menu {
    padding: 5px 0; /* 드롭다운 메뉴 패딩 감소 */
  }

  .dropdown-item {
    font-size: 12px; /* 드롭다운 항목 글자 크기 줄임 */
    padding: 8px; /* 드롭다운 항목 패딩 감소 */
  }
}