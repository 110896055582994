/* Google Fonts에서 Noto Sans 가져오기 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

/* 전체 폰트를 Noto Sans로 지정 */
body,
.download-container,
.download-title,
.tab-list,
.tab-item,
.download-card,
.button,
.document-image {
  font-family: 'Noto Sans', sans-serif;
}

/* 컨테이너, 탭 스타일 */
.download-container {
  max-width: 80%;
  margin: 40px auto;
  background: #ffffff;
  border-radius: auto;
  box-sizing: border-box;
  box-shadow: 0 px 10px rgba(0, 0, 0, 0.1);

}

.download-container h2 {

  text-align: center;
}

.tab-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.tab-item {
  position: relative;
  flex: 1;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;
  border-right: 1px solid #ddd;
}
.tab-item:last-child {
  border-right: none;
}

.tab-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #4e61aa;
}

.tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* 개별 카드 스타일 */
.download-card {
  max-width: 300px;     /* 카드 최대 너비 */
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;

}

/* 카드들이 들어가는 flex 컨테이너 */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 좌측 정렬 */
  gap: 20px; /* 카드 사이의 간격 */
}

/* 이미지 스타일: 작게 고정되도록 */
.document-image {
  border: 1px solid transparent;
  width: 100%;
  max-width: 250px; /* 이미지 최대 너비 */
  height: auto;
  display: block;
  margin: 0 auto;
}

/* 타이틀 스타일 */
.download-title {
  margin: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

/* 다운로드 버튼 스타일 */
.download-button {
  background-color: #4e61aa;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  padding: 10px 20px;
  background-color: #4e61aa;
  color: white;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

.download-button:hover {
  color: black; /* 호버 시 글씨가 검은색 */
}

.document-title {
  width:  100%;               /* 이미지와 동일한 너비 */
  border: 1px solid #ddd;     /* 테두리 설정 */
  margin: auto;          /* 위아래 간격 및 가로 중앙 정렬 */
  justify-content: center;    /* 수평 중앙 정렬 */
  align-items: center;        /* 수직 중앙 정렬 */
  border-radius: 4px;         /* 둥근 모서리 (선택사항) */
  box-sizing: border-box;     /* 패딩 포함 너비 계산 */
  text-align: center;         /* 텍스트 중앙 정렬 (보조) */
  background-color: #d7d7d7;
}

.modal-container {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 15px 25px;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeInOut 5s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}