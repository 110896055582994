.footer {
  background-color: #354b8c; /* 배경색 */
  color: white; /* 텍스트 색상 */
  padding: 20px;
  font-size: 0.9rem;
}

.footer-container {
  align-items: center;
  display: flex;
  gap: 220px;
}

.footer-container-left {
  justify-content: center; /* 가운데 정렬 */
  align-items: center;
}

.footer-container-right {
  display: inline-block; /* 가로 정렬 */
}

.footer-info span {
  display: inline-block; /* 인라인 블록으로 설정 */
  margin-right: 20px; /* 요소 간 간격 추가 */
}

.footer-info span:last-child {
  margin-right: 0; /* 마지막 요소의 여백 제거 */
}

.company-name {
  font-size: 1.7rem;
  font-weight: bold;
  margin-left: 60px; /* 왼쪽 여백 추가로 오른쪽 이동 */
}

.footer-copyright {
  text-align: center; /* 텍스트 가운데 정렬 */
  width: 100%; /* 부모 컨테이너 전체를 차지 */
  margin: 0; /* 기본 여백 제거 */
  padding: 0; /* 기본 패딩 제거 */
}

/* 반응형 디자인 */
@media (max-width: 1500px) {
  .footer-container {
    gap: 80px; /* 줄바꿈 방지를 위한 간격 조정 */
    flex-wrap: nowrap; /* 줄바꿈을 허용하지 않음 */
  }

  .footer-container-left {
    align-items: center;
    display: flex; /* Flexbox 강제 적용 */
    flex-shrink: 0; /* 크기 축소 방지 */
  }

  .footer-container-right {
    text-align: center;
  }

  .footer-info {
    text-align: center;
  }

  .footer-copyright {
    text-align: center;
  }

  .company-name {
    margin-left: 0;
  }
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    gap: 10px; /* 모바일에서는 gap 값을 덮어쓰기 */
  }

  .footer-container-right {
    text-align: center;
  }

  .footer-info {
    text-align: center;
  }

  .footer-copyright {
    text-align: center;
  }

  .company-name {
    margin-left: 0;
  }
}
