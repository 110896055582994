/* HeadBanner.css */

.head-banner-container {
  position: relative; /* 내부 요소들이 배너 영역을 기준으로 위치하도록 설정 */
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.head-banner-image-container {
  width: 100%;
  height: auto;
  object-fit: cover; /* 이미지 비율 유지하며 배너 크기에 맞춤 */
}

/* 텍스트를 이미지 중앙에 위치시키는 스타일 */
.head-banner-text-about {
  position: absolute;
  top: 65%; /* 수직 중앙 정렬 */
  left: 50%; /* 수평 중앙 정렬 */
  transform: translate(-50%, -50%); /* 요소의 중심을 기준으로 위치 조정 */
  color: #ffffff; /* 텍스트 색상 */
  font-size: 2rem; /* 텍스트 크기 조정 */
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 가독성을 위한 텍스트 그림자 */
}

/* business 하위 페이지 링크 스타일 */
.business-subpages {
  margin-top: 10px;
  display: flex;
  /* 원래 좌우 배치를 위해 space-between, 또는 flex-start 등으로 조정하세요 */
  justify-content: space-between;
  align-items: center;
  /* 가운데 정렬을 위한 절대 위치 기준점 */
  gap: 20px; /* 링크 간 간격 조절 */
}

.business-link {
  color: #808080;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s;
}

/* Hover 시 색상만 변경 */
.business-link:hover {
  text-decoration: none;
  color: #ffffff;
}

/* 액티브(또는 호버 시) 가운데 정렬 & 폰트 커짐 */
.business-link.active {
  color: #ffffff;
  font-weight: bold;
  font-size: 2.0rem;
  text-align: center;
  /* 가운데 위치를 위한 절대배치 */
  position: relative;
}

@media (max-width: 768px) {

  .head-banner-image-container {
    height: 350px;
  }

  .head-banner-text-about {
    font-size: 1rem; /* 텍스트 크기 조정 */
    width: 100%;
  }

  /* 모바일에서 비활성(.active 아님) 링크는 안 보이게 */
  .business-link:not(.active) {
    display: none;
  }

  /* 모바일에서 active 링크는 크기 조정 */
  .business-link.active {
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: bold;
  }

  .business-subpages {
    flex-direction: column;
    font-size: 1.8rem;
    gap: 2px;
  }
}