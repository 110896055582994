/* AdminPage.css */
.admin-page-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* 중앙 정렬 */
    gap: 10px; /* 버튼 간격 */
    margin: 30px 30px;
}

.admin-page-container h3 {
    font-weight: bold; /* 텍스트 굵게 설정 */
}

.admin-page-container button {
    width: 250px; /* 버튼 너비 설정 */
    padding: 10px;
    font-size: 16px;
    text-align: center;
}
