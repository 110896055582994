/* 기존 CSS */

.banner-container {
  position: relative;
  text-align: center;
}

.banner-image {
  width: 100%;
  height: 100vh; /* 화면 높이에 맞춤 */
  object-fit: cover; /* 이미지 비율 유지 */
}

.main-text {
  position: absolute;
  top: 42%;
  font-weight: bold;
  color: white; /* 텍스트 색상 */
  font-size: clamp(1.5rem, 5vw, 3.5rem); /* 최소 1.5rem, 최대 4rem */
  padding: 0 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center; /* 텍스트를 가로로 중앙 정렬 */
  width: 100%;
}

.hero-text-overlay {
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%; /* 가로 중앙 정렬 */
  color: #0b0909;
  font-weight: bold;
  width: 100%;
  padding: 30px;
  transform: translateX(-50%); /* 가로 방향으로만 중앙 정렬 */
}

.hero-text-overlay h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-text-overlay .lead {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.hero-text-overlay form {
  max-width: 600px;
  margin: 0 auto;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 중앙 정렬 */
  gap: 20px; /* 카드 간 간격 */
  margin-top: 40px;
  position: relative; /* 컨테이너 내에서 상대적 위치 */
  z-index: 0; /* 카드 컨테이너의 z-index를 0으로 설정 */
}

.card {
  max-width: 250px; /* 카드 너비 설정 */
  margin-bottom: 50px;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  /* 호버 시 약간 확대 */
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card img {
  height: 130px;
  display: block;
  overflow: hidden;
}

.card-title {
  font-weight: 550;
  font-size: 1.50em;
}

/* Header.css */

/* 이전 및 다음 버튼을 카드 옆으로 배치 */
.carousel-control-prev,
.carousel-control-next {
  width: 30px; /* 클릭 영역을 좀 더 키움 */
  height: 30px;
  top: 50%; /* 세로 중앙 정렬 */
  transform: translateY(-20%); /* 가로 이동 대신 세로 중앙으로 이동 */
  position: absolute;
  z-index: 1; /* 버튼을 최상위 레이어로 */
}

/* 버튼 클릭 영역을 정확히 아이콘 크기로 맞춤 */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2); /* 버튼 배경을 살짝 어둡게 */
  border-radius: 20%; /* 둥글게 만듦 */
  padding: 6px;
}

/* 버튼에 호버 시 색상 변경 */
.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  background-color: #4e61aa; /* 호버 시 파란색으로 변경 */
}

/* 이전 및 다음 버튼 위치 조정 */
.carousel-control-prev {
  left: 32%; /* 카드의 바로 왼쪽에 위치 */
}

.carousel-control-next {
  right: 32%; /* 카드의 바로 오른쪽에 위치 */
}

/* 인디케이터 스타일 */
.carousel-indicators {
  bottom: -30px; /* 인디케이터를 카드 아래로 이동 */
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5); /* 인디케이터 기본 색상 */
}

.carousel-indicators .active {
  background-color: #4e61aa; /* 활성화된 인디케이터의 색상 */
}

/* 스크롤 다운 아이콘 스타일 */
.scroll-down-arrow {
  position: absolute;
  bottom: 20px; /* 화면 하단에서의 거리 */
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.scroll-down-arrow i {
  font-size: 2rem; /* 아이콘 크기 */
  color: #fff; /* 아이콘 색상 */
  animation: bounce 2s infinite; /* 애니메이션 효과 */
}

/* 반응형 카드 배치 */
@media (max-width: 1268px) {
  .card-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%; /* 카드 컨테이너 너비를 80%로 줄임 */
    margin: 10px auto;
  }

  .card {
    flex: 0 0 auto;
    width: 130px; /* 카드 너비를 더 작게 설정 */
  }

  .card-text,
  .card-footer {
    display: none;
  }
  .card:last-child {
    margin-right: 0;
  }

  .card img {
    width: 100%;
    height: 80px; /* 이미지 높이 감소 */
  }

  .card-title {
    font-size: 0.8em; /* Adjust font size if needed */
  }

  .card-body {
    padding: 7px; /* Adjust padding if needed */
  }

  .btn {
    font-size: 0.7em; /* 버튼 폰트 크기 감소 */
    padding: 3px 6px; /* 버튼 패딩 감소 */
  }
  .hero-text-overlay h1 {
    font-size: 2rem;
    font-weight: bold;
  }
  .hero-text-overlay .lead {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  /* >>> 모바일에서 카드 호버/클릭 시 움직이지 않도록 설정 <<< */
  .card:hover,
  .card:focus,
  .card:active {
    transform: none !important;
    box-shadow: none !important;
  }
}
/* Swiper 컨테이너 스타일 */
.swiper {
  width: 100%;
  max-width: 1200px; /* 중앙 정렬을 위한 최대 너비 설정 */
  margin: 0 auto; /* 중앙 정렬 */
  overflow: hidden;
  padding-bottom: 30px;
}

/* Swiper 슬라이드 스타일 */
.swiper-slide {
  display: flex; /* 이미지와 내용 정렬 */
  justify-content: center; /* 가로로 중앙 정렬 */
  align-items: center; /* 세로로 중앙 정렬 */
  box-sizing: border-box;
  padding: 30px;
}

/* 이미지가 중앙에 보이도록 설정 */
.small-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.small-card-img {
  max-width: 100%;
  max-height: 100px; /* 최대 높이 설정 */
  object-fit: contain; /* 이미지 비율 유지 */
}

/* 이미지와 텍스트가 정렬된 줄의 가운데를 유지 */
.swiper-wrapper {
  display: flex;
  align-items: center; /* 전체 슬라이드의 중앙 정렬 */
}
