
.navbar-comp {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  transition: top 0.3s ease;
}

.navbar-comp.hidden {
  top: -65px; /* 헤더와 함께 사라지도록 위로 숨김 */
}

.navbar-comp.visible {
  top: 0; /* 헤더와 함께 나타남 */
}


/* head-navi 클래스로 스타일 범위를 제한합니다 */
.head-navi {
  position: fixed;
  background: white;
  width: 100%;
  z-index: 10;
  height: 70px; /* 기본 높이 설정 */
}

.head-navi .navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 로고는 왼쪽, 메뉴는 오른쪽 */
  max-width: 1200px; /* 필요에 따라 조정 */
  margin: 0 auto;
  padding: 0 20px;
}

/* navbar-menu를 가로로 배치하고 가운데 정렬 */
.head-navi .navbar-menu {
  list-style: none;
  display: flex; /* 가로 배치 */
  justify-content: flex-end; /* 오른쪽 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
  margin: 0;
  padding: 0;
}

/* 각 메뉴 아이템에 여백 추가 */
.head-navi .navbar-item {
  margin: 0 15px; /* 좌우 여백 */
  position: relative;
}

/* 링크 스타일 */
.head-navi .navbar-link {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  padding: 15px 0; /* 상하 패딩 */
}

/* 호버 효과 (배경 투명 유지) */
.head-navi .navbar-link:hover {
  text-decoration: underline; /* 호버 시 밑줄 표시 */
}

/* 드롭다운 메뉴 스타일 */
.head-navi .dropdown-menu-1 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%); /* 부모 메뉴 아래에 가운데 정렬 */
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 드롭다운 아이템 스타일 */
.head-navi .dropdown-item .dropdown-link {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 0.9rem; /* 글자 크기를 키움 */
  text-transform: uppercase; /* 텍스트를 대문자로 변환 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}

/* 드롭다운 아이템 호버 효과 */
.head-navi .dropdown-item .dropdown-link:hover {
  background-color: #f0f0f0;
}

/* 로고 이미지 스타일 */
.head-navi .navbar-logo {
  position: absolute;
  left: 0;
}

.head-navi .head-image {
  height: 35px; /* 필요에 따라 조정 */
}

/* 기존 드롭다운 메뉴 스타일 유지 */
.head-navi .dropdown-menu-1 {
  position: absolute;
  top: 100%;
  /* 기타 스타일 */
}

/* drop-up 클래스 추가 */
.head-navi .dropdown-menu-1.drop-up {
  top: auto;
  bottom: 100%;
}

/* 모바일 화면에서 고정된 위치 유지 */
@media (max-width: 768px) {
  .logout-button {
    display: none;
  }

  .head-navi {
    width: 100vw; /* 화면 너비에 맞춤 */
    overflow-x: hidden; /* 가로 스크롤 방지 */
    height: 60px; /* 모바일 뷰에서 고정 높이 설정 */
  }

  .head-navi .navbar-container {
    position: relative;
  }

  .head-navi .navbar-logo {
    position: relative;
    left: -120px;
    top: 8px;
    height: 40px;
  }

  /* 메뉴 항목 숨기기 */
  .head-navi .navbar-menu {
    display: none;
  }

  .head-navi .head-image {
    height: 30px; /* 필요에 따라 조정 */
  }

}

/* 로그아웃 버튼 스타일 */
.logout-button {
  position: absolute; /* 절대 위치 지정 */
  right: 80px; /* 오른쪽 끝에 배치 */
  background-color: #4e61aa; /* 버튼 색상 */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #4e61aa;
}