.about-full-width-background {
  background-color: #ffffff;
  padding: 2rem 0;
}

.about-title-comp {
  color: black;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: bold;
}

.title-comp {
  color: black;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: bold;
}

.about-orangecolor {
  color: #ea8440;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}

/* 리스트 항목 스타일 */
.about-process-wrapp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.about-process-wrapp li {
  width: 100%;
  max-width: 300px;
  background-color: #c8dadc;
  padding: 1.5rem;
  margin: 2rem; /* 기존 1rem에서 2rem으로 증가 */
  /*position: relative;*/
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-process-wrapp li:hover {
  transform: translateY(-10px);
}

/* 아이콘 스타일 */
.about-pro-ico {
  display: block;
  margin: 0 auto 1rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-pro-ico img {
  width: 70px;
  height: 70px;
}

.about-font-normal {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.about-check {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.about-check::before {
  content: '\f00c'; /* Font Awesome 체크 마크 코드 */
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #007bff;
  position: absolute;
  left: 0;
  font-size: 1rem;
}

.about-ceo-text-container {
  padding: 70px 0 30px 0;
  width: 100%; /* 이미지와 동일한 너비로 설정 */
  max-width: 800px; /* 너비 제한 */
  margin: 0 20px 100px; /* 위, 좌우, 아래 여백 */

  }
.about-ceo-image {

  position: relative;
  max-width: 100%;
  height: auto; /* 이미지의 높이를 비율에 맞게 자동 조정 */
  border-radius: 25px; /* 이미지의 둥글기 유지 */
  width: 100%;
  object-fit: cover;
  z-index: 999;
}

.about-ceo-text-container li {
  padding:  0;
  width: 100%; /* 이미지와 동일한 너비로 설정 */
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 1.5rem; /* Adds spacing between items */

}
.about-ceo-title {
  font-size: 3rem;
}

/* 모바일 전용 스타일 */
@media (max-width: 768px) {
  .about-full-width-background {
    padding: 1.5rem 0;
  }

  .about-title-comp {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .about-orangecolor {
    font-size: 1rem;
  }

  .about-process-wrapp li {
    padding: 1rem;
    margin: 1rem;
    max-width: 60%; /* 최대 너비 조정 */
  }

  .about-font-normal {
    font-size: 1.1rem;
  }

  .about-check {
    font-size: 0.9rem;

  }

  .about-ceo-image {
    padding: 1rem;
    width: 100%;
    max-width: 100%;
    margin: 10px 0 20px 0;
  }

  .about-ceo-text-container {
    padding: 20px;
    max-width: 100%;
    margin: 0;
  }

  .about-ceo-text-container li {
    font-size: 0.9rem;
  }
}

